import { ReactNode, useState } from "react";
import { useTranslation } from "next-i18next";
import RightRideLogo from "@public/images/logo-rightride.svg";
import RightRideLogoDark from "@public/images/logo-rightride-dark.svg";
import LoginFlowWrapper from "@common/components/LoginFlowWrapper";
import { LoginModalMode, loginMode } from "@modules/user/types/LoginModalMode";
import useUser from "@modules/user/hooks/useUser";
import CustomerAvatar from "@common/components/CustomerAvatar";
import LocalImage from "@common/components/LocalImage";
import { Button } from "@talladega/components";
import { ArrowRightIcon } from "@talladega/icons";
import { useRouter } from "next/router";
import Link from "next/link";

type Props = {
  children: ReactNode[] | ReactNode;
  onLogin?: () => void;
};

const GoodbyeLayout = ({ children, onLogin }: Props) => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { user } = useUser();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loginModalMode, setLoginModalMode] =
    useState<LoginModalMode>(loginMode);
  const currentYear = new Date().getFullYear();
  return (
    <LoginFlowWrapper
      isLoginModalOpen={showLoginModal}
      onLoginModalClose={() => setShowLoginModal(false)}
      loginModalMode={loginModalMode}
      onLogin={onLogin}
    >
      <div className="flex flex-col min-h-screen">
        <div className="fixed inset-x-0 top-0 z-50 h-20 bg-white">
          <div className="flex justify-between items-center mx-6 lg:mx-[120px] mt-4">
            <Link
              href="/"
              passHref
              role="link"
              tabIndex={0}
              className="[&>*]:align-middle focus:outline-none focus-visible:shadow-elevation-03"
            >
              <LocalImage
                width="173"
                height="39"
                className="hover:cursor-pointer"
                src={RightRideLogo}
                alt="RightRide Logo"
                priority
              />
            </Link>
            {user?.isLoggedIn ? (
              <Button
                fill="link"
                spacing="tight-hug"
                size="small"
                className="border border-gray-50 px-[.1rem]"
                rightIcon={<ArrowRightIcon />}
                onClick={() => {
                  router.push("/profile");
                }}
              >
                <div className="flex items-center text-black max-w-[10rem] xl:max-w-[14rem]">
                  <CustomerAvatar
                    customer={user}
                    size="sm"
                    isCurrentUser
                    isProfileAvatar
                  />
                  <p className="text-ellipsis whitespace-nowrap overflow-hidden pl-3">
                    <span>{user.firstName}</span>{" "}
                    <span data-hj-suppress>{user.lastName}</span>
                  </p>
                </div>
              </Button>
            ) : (
              <button
                className="body-3 text-primary-500 underline "
                onClick={() => {
                  setShowLoginModal(true);
                  setLoginModalMode(loginMode);
                }}
                type="button"
              >
                {t("login")}
              </button>
            )}
          </div>
        </div>
        <div className="mt-20 flex-1">{children}</div>
        <div className="bg-primary-500 py-16">
          <LocalImage
            src={RightRideLogoDark}
            alt="RightRide Logo"
            className="mx-auto flex flex-shrink"
          />
          <div className="flex flex-row justify-center gap-x-10 text-center text-white mt-5 caption-4">
            <p>{`© ${currentYear} ${t("rightride")}`}</p>
            <Link href="/privacy-policy" className="underline">
              {t("privacy_policy")}
            </Link>
            <Link href="/terms-conditions" className="underline">
              {t("terms_and_conditions")}
            </Link>
          </div>
        </div>
      </div>
    </LoginFlowWrapper>
  );
};

export default GoodbyeLayout;
