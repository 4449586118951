import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { GetStaticPropsContext } from "next";
import { dehydrate } from "@tanstack/react-query";
import { getServerSideQueryClient } from "@modules/api/queryClients";
import getCmsPageData from "@modules/cms/api/getCmsPageData";
import SeoInfo from "@common/components/SeoInfo";
import SeoInfoProps from "@common/types/SeoInfoProps";
import getCanonicalUrl from "@common/helpers/getCanonicalUrl";
import LocalImage from "@common/components/LocalImage";
import HomeHero from "@public/images/home-hero.png";
import { ReactElement } from "react";
import { Button } from "@talladega/components";
import { useRouter } from "next/router";
import {
  ArrowRightIcon,
  DollarCoinIcon,
  FileIcon,
  ShieldCheckOutlineIcon,
} from "@talladega/icons";
import GoodbyeLayout from "@layouts/public/components/GoodbyeLayout";

type Props = {
  cmsSeoInfo: SeoInfoProps | null;
};

const IndexPage = ({ cmsSeoInfo }: Props) => {
  const router = useRouter();
  return (
    <>
      {cmsSeoInfo && <SeoInfo seoInfo={cmsSeoInfo} />}
      <div className="bg-primary-500 relative py-24">
        <div className="flex flex-col justify-center max-w-[740px] px-4 mx-auto text-center text-white gap-y-5">
          <LocalImage
            src={HomeHero}
            alt="Home Hero"
            width={498}
            height={330}
            className="mx-auto"
          />
          <h2 className="font-semibold text-nowrap">Farewell & Thank You</h2>
          <p>
            RightRide has closed operations. We are
            <span className="font-semibold">
              {" "}
              deeply grateful for the support{" "}
            </span>
            of our customers, the dedication of our employees, and the
            partnerships we&apos;ve forged over the years. We appreciate your
            understanding and apologize for any inconvenience. Thank you for
            being a part of our journey. Sincerely, The RightRide Team
          </p>
          <p className="font-semibold">
            Thank you for being a part of our journey.
          </p>
          <p>
            Sincerely,
            <br />
            The RightRide Team
          </p>
        </div>
      </div>
      <div className="bg-white bg-cover text-center py-20 px-4">
        <div className="max-w-[860px] mx-auto space-y-3">
          <h2 className="text-primary-500 font-semibold">
            Have any questions or concerns regarding a previous purchase you
            made from RightRide?
          </h2>
          <p>
            Please send an email to{" "}
            <a
              href="mailto:hello@rightride.com"
              className="font-semibold underline"
            >
              hello@rightride.com
            </a>
          </p>
        </div>
      </div>
      <div className="bg-gray-25 py-20 px-4">
        <h2 className="text-center max-w-[758px] mx-auto font-semibold">
          Our RightRide Digital services featured on Kijiji are moving to a new
          home
        </h2>
        <div className="flex flex-row flex-wrap justify-center w-full gap-6 mt-10">
          <div className="flex flex-col justify-between items-center text-center px-6 py-10 bg-white max-w-[344px] rounded-lg gap-y-4 gap-x-6">
            <div className="bg-secondary-alt rounded-full p-2">
              <ShieldCheckOutlineIcon className="w-10 h-10 text-primary-500" />
            </div>
            <h3>Warranty</h3>
            <p>
              If you have a Warranty quote or purchased coverage, RightRide is
              still servicing these customers as we move operations to
              <span className="font-semibold">
                {" "}
                Roadwise by AutoCanada – Coming soon!
              </span>
            </p>
            <Button
              fill="filled"
              color="primary"
              className="w-full"
              spacing="tight-hug"
              rightIcon={<ArrowRightIcon />}
              onClick={() => router.push("/purchase/coverage")}
            >
              Warranty Program
            </Button>
          </div>
          <div className="flex flex-col justify-between items-center text-center px-6 py-10 bg-white max-w-[344px] rounded-lg gap-y-4 gap-x-6">
            <div className="bg-secondary-alt rounded-full p-2">
              <DollarCoinIcon className="w-10 h-10 text-primary-500" />
            </div>
            <h3>Instant Cash Offer</h3>
            <p>
              RightRide is still servicing customers who received an instant
              cash offer for their car on Kijiji or AutoCanada websites as we
              move operations over to
              <span className="font-semibold"> Throttle – Coming soon!</span>
            </p>
            <Button
              fill="filled"
              color="primary"
              className="w-full"
              spacing="tight-hug"
              rightIcon={<ArrowRightIcon />}
              onClick={() => router.push("/purchase/offer?source=kijiji")}
            >
              Cash Offer Program
            </Button>
          </div>
          <div className="flex flex-col justify-between items-center text-center px-6 py-10 bg-white max-w-[344px] rounded-lg gap-y-4 gap-x-6">
            <div className="bg-secondary-alt rounded-full p-2">
              <FileIcon className="w-10 h-10 text-primary-500" />
            </div>
            <h3>Bill of Sale</h3>
            <p>
              Our free bill of sale generator for Kijiji customers selling their
              vehicle privately will still be available on RightRide as we move
              these features to
              <span className="font-semibold"> Throttle – Coming soon!</span>
            </p>
            <Button
              fill="filled"
              color="primary"
              className="w-full"
              spacing="tight-hug"
              rightIcon={<ArrowRightIcon />}
              onClick={() => router.push("/billofsale/kijiji")}
            >
              Bill of Sale Generator
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

IndexPage.pageName = "IndexPage";
IndexPage.getLayout = (page: ReactElement) => (
  <GoodbyeLayout>{page}</GoodbyeLayout>
);

export const getStaticProps = async (context: GetStaticPropsContext) => {
  const queryClient = getServerSideQueryClient();

  const { seoInfo } = await getCmsPageData(
    "home",
    context.preview,
    context.locale?.toUpperCase()
  );

  if (seoInfo && !seoInfo.canonical) {
    seoInfo.canonical = getCanonicalUrl("home");
  }
  if (seoInfo && seoInfo.opengraphUrl) {
    seoInfo.opengraphUrl = getCanonicalUrl("home");
  }

  return {
    props: {
      cmsSeoInfo: seoInfo,
      ...(await serverSideTranslations(context.locale ?? "en", ["common"])),
      dehydratedState: dehydrate(queryClient),
    },
  };
};

export default IndexPage;
